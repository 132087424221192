// Fee Name api Routes
export const feeNameList = '/configuration/fee-name/list'
export const feeNameStore = '/configuration/fee-name/store'
export const feeNameUpdate = '/configuration/fee-name/update'
export const feeToggleStatus = '/configuration/fee-name/toggle-status'
// configure step
export const stepSearchView = '/step/get-search-form'
export const dynamicFormAppRej = '/step/step-form/approve-reject'
export const forwardStore = '/step/forward/store'
export const returnForward = '/step/forward/return-application'
export const designationWiseUser = '/step/forward/employee-list'
// dynamic fomr view
export const stepFormView = '/step/get-entry-form'
export const stepFormTab = '/step/get-form-tab'
export const stepFormData = '/step/get-form-data'
export const stepFormDetailsData = '/step/step-from-data'
// dynamic form view
export const stepDataList = '/step/get-all-services'
export const stepSendDataList = '/step/get-send-list'
// Dynamic Form Data Store In DB By User
export const formDataStore = 'step/step-form/store'
export const formDataUpdate = 'step/step-form/edit'
export const surveyDataStore = 'step/step-form/survey-store'
export const actionFormStore = 'step/step-form/get-action-form'
export const changeLogStore = '/step/step-change/store'
// Document View routes
export const documentView = 'step/get-document'
// Forward list
export const appForwardList = 'step/forward/app-forward-List'
// License Action Button
export const licenseCancel = '/step/license-action/cancel'
export const licenseCancelList = '/step/license-action/cancel-list'
export const licenseTransferStore = '/step/license-action/transferStore'
export const licenseTransferList = '/step/license-action/transfer-list'
export const licenseWithdrawList = '/step/license-action/withdraw-list'
export const licenseWithdraw = '/step/license-action/withDraw'
export const approveIssue = '/step/license-action/approve-issue'
export const stepApproveIssue = '/step/license-action/approve-save-issue'
export const licenseIssue = '/step/document-issue/issue'
export const servicePublish = '/step/license-action/publish'
export const servicePublishwithDoc = '/step/license-action/publishWithDoc'
export const paymentList = '/payments/list'
export const challanList = '/challan/list'
// Report Data List
export const reportDataList = '/step/report/service-report'
export const reportSearchView = '/step/report/get-search-form'
// Report Api...
export const expirationPassedReportList = '/step/report/get-expiration-passed'
export const numericalReportList = '/step/report/numerical-report'
// Application Info
export const applicationInfocancel = '/step/license-action/application-info-cancel'
export const applicationInfowithdraw = '/step/license-action/application-info-withdraw'
export const applicationInfoApi = '/step/license-action/application-info'
// Get Entry Form
export const userEntryForm = '/external-panel/step/get-entry-form'
export const userEntryStore = '/external-panel/step/user-entry-form-store'
// Dae Lab Assign  Store
export const daeLabAssignStore = '/configuration/dae-lab-assign/store'
export const daeLabAssignUpdate = '/configuration/dae-lab-assign/update'
export const assignDataApi = '/configuration/dae-lab-assign/lab-assign'
// Dae Lab Report Store
export const daeLabReportStore = '/configuration/dae-lab-report/store'
// load old data
export const loadOldFormData = '/external-panel/step/load-old-form-Data'
// Get Payment Receipt Data from External Panel
export const paymentReceipt = '/application/payment/all'
export const paymentStoreOnline = '/application/online-store'
export const paymentStoreOffline = '/application/offline-store'
export const getTypeCheckboxPaymentList = '/application/payment/type-checkbox-payment'
export const getCheckboxPaymentList = '/application/payment/checkbox-payment'
export const getOtherPayment = '/application/payment/other-payment'
export const getLabPaymentFromMainPayment = '/application/payment/lab-payment'
// Get Payment Receipt Data from External Panel
export const reportData = '/step/report/service-summary-report'
// Get Boro Import Applications With Demanded Data
export const boroRecommeendationList = '/step/boro-import/applications'
export const boroUpdatedRecommendationList = '/step/boro-import/recommended-data'

export const reSubmitApi = '/step/license-action/resubmit'
export const licenseToNothi = '/nothi/send-to-nothi'
// Office Entry Application List
export const officeEntryList = '/step/office-entry-list'

// Archive Applications List
export const archiveApplications = '/archive/applications'
export const licenseArchive = '/archive/archive-store'

export const damReport = '/step/report/dam-report'
export const damReportConf = '/step/report/dam-config'

export const chalanSearchs = '/step/chalan-search/list'
export const daeAttachment = '/step/dae-attachment/search'
export const daeAttachmentSave = '/step/dae-attachment/store'

// Seed Dealer Recruitment Circular
const recruitment = '/step/circular/'
export const recruitmentList = recruitment + 'list'
export const recruitmentStore = recruitment + 'store'
export const recruitmentHistoryStore = recruitment + 'history-store'
export const recruitmentUpdate = recruitment + 'update'
export const recruitmentCircularPublish = recruitment + 'publish'

export const staticSeedDealerCertificate = 'step/seed-dealer-certificate'
export const staticSeedDealerScaSend = 'step/seed-dealer-scasend'
export const daeImportReport = '/step/report/dae-import-report'

// Change Certificate Request List
export const changeCertificateRequestList = '/step/change-certificate/list'
export const changeCertificateDataList = '/step/change-certificate/change-certificate-data'
export const certificateChangeStore = '/step/change-certificate/change-certificate-store'
export const certificateChangeDamStore = '/step/change-certificate/change-certificate-dam-store'
export const certificateChangeBadcSeedStore = '/step/change-certificate/change-certificate-badc-Seed-store'
export const certificateChangeBadcFertilizerStore = '/step/change-certificate/change-certificate-badc-fertilizer-store'

export const paymentHistoryReport = '/step/report/payment-history-report'
export const importExportReport = '/step/report/export-import-report'
export const registrationFeeVarReport = '/step/report/registration-fee-vat-report'
export const seedDealerRenewalReport = '/step/report/seed-dealer-renewal-appointment-report'
export const securityCollectionReport = '/step/report/security-collection-report'
export const acceptanceAndDisposalReport = '/step/report/acceptance-and-disposal-report'
export const daeDocumentIssueReport = '/step/report/dae-document-issue-report'
